import Vue from 'vue'
import Vuex from "vuex";

import appConfiguration from "./modules/appConfiguration.module";
import dashboard from "./modules/dashboard.module";
import adminUser from './modules/adminUser.module';
import user from './modules/user.module';
import domain from './modules/domain.module';
import report from './modules/report.module';
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        appConfiguration,
        dashboard,
        adminUser,
        user,
        domain,
        report
    },
    strict: true
})