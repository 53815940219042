import helperApi from "../helper/helper.api";
import store from "../../store";
const findAllDomainsForUser = "Domain/FindAll";

export default {
    async findAllDomainsForUser(user) {
        const result = await helperApi.getRequest({
            needAuthroization: true,
            url: store.state.appConfiguration.apiUrl + findAllDomainsForUser,
            accessToken: user.jwtToken
        })
        return result;
    }
}