import reportAPI from "../../api/report/report.api";
const state = () => ({
    chromePluginReports: {
        researchParnter: null,
        discoveryAgent: null,
        summaryAgent: null,
        seoAgent: null,
        backlinkAgent: null,
    }
});

const getters = {
    getChromePluginReports(state) {
        return state.chromePluginReports;
    }
}
const actions = {
    async getResearchPartnerReports(obj, user) {
        return new Promise((resolve) => {
            reportAPI
                .getResearchPartnerReports(user)
                .then((result) => {
                    // commit("setResearchPartnerReport", result);
                    resolve(result);
                })
                .catch(() => {
                    resolve({ success: false });
                });
        })
    },
    async getDiscoveryAgentReports(obj, user) {
        return new Promise((resolve) => {
            reportAPI
                .getDiscoveryAgentReports(user)
                .then((result) => {
                    // commit("setDiscoveryAgentReport", result);
                    resolve(result);
                })
                .catch(() => {
                    resolve({ success: false });
                });
        })
    },
    async getSummaryAgentReports(obj, user) {
        return new Promise((resolve) => {
            reportAPI
                .getSummaryAgentReports(user)
                .then((result) => {
                    // commit("setSummaryAgentReport", result);
                    resolve(result);
                })
                .catch(() => {
                    resolve({ success: false });
                });
        })
    },
    async getSEOAgentReports(obj, user) {
        return new Promise((resolve) => {
            reportAPI
                .getSEOAgentReports(user)
                .then((result) => {
                    // commit("setSEOAgentReport", result);
                    resolve(result);
                })
                .catch(() => {
                    resolve({ success: false });
                });
        })
    },
    async getBacklinkAgentReports(obj, user) {
        return new Promise((resolve) => {
            reportAPI
                .getBacklinkAgentReports(user)
                .then((result) => {
                    // commit("setBacklinkAgentReport", result);
                    resolve(result);
                })
                .catch(() => {
                    resolve({ success: false });
                });
        })
    },
    async getCampaignReportsForUser(obj, user) {
        return new Promise((resolve) => {
            reportAPI
                .getCampaignReportsForUser(user)
                .then((result) => {
                    // commit("setBacklinkAgentReport", result);
                    resolve(result);
                })
                .catch(() => {
                    resolve({ success: false });
                });
        })
    }
}

const mutations = {
    setResearchPartnerReport(state, chromePluginReports) {
        state.chromePluginReports.researchParnter = chromePluginReports;
    },
    setDiscoveryAgentReport(state, chromePluginReports) {
        state.chromePluginReports.discoveryAgent = chromePluginReports;
    },
    setSummaryAgentReport(state, chromePluginReports) {
        state.chromePluginReports.summaryAgent = chromePluginReports;
    },
    setSEOAgentReport(state, chromePluginReports) {
        state.chromePluginReports.seoAgent = chromePluginReports;
    },
    setBacklinkAgentReport(state, chromePluginReports) {
        state.chromePluginReports.backlinkAgent = chromePluginReports;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}