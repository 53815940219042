import domainApi from "../../api/domain/domain.api";


const actions = {
    async getDomainsForUser(obj, user) {
        return new Promise((resolve) => {
            domainApi
                .findAllDomainsForUser(user)
                .then((result) => {
                    // commit("setDomains", result);
                    resolve( result );
                })
                .catch(() => {
                    resolve({ success: false });
                });
        })
    }
}


export default {
    namespaced: true,
    actions,
}