import getEnvironment from "@/helpers/getEnvironment";

var apiURL = "";
var dashboardURL = "";
var researchPartnerURL = "";
var discoveryAgentURL = "";
var summaryAgentURL = "";
var seoAgentURL = "";
var backlinkAgentURL = "";

const environment = getEnvironment();
switch (environment) {
  case "local":
    apiURL = "https://stg-gateway.bridged.media/";
    dashboardURL = "https://stg-dashboard.bridged.media/";
    researchPartnerURL = "https://stg-ai-agents-api.bridged.media/";
    discoveryAgentURL = "https://stg-ai-agents-api.bridged.media/";
    summaryAgentURL = "https://summary-agent.bridged.media/";
    seoAgentURL = "https://seo-agent.bridged.media/";
    backlinkAgentURL = "https://backlink-agent.bridged.media/";
    break;
  case "staging":
    apiURL = "https://stg-gateway.bridged.media/";
    dashboardURL = "https://stg-dashboard.bridged.media/";
    researchPartnerURL = "https://stg-ai-agents-api.bridged.media/";
    discoveryAgentURL = "https://stg-ai-agents-api.bridged.media/";
    summaryAgentURL = "https://summary-agent.bridged.media/";
    seoAgentURL = "https://seo-agent.bridged.media/";
    backlinkAgentURL = "https://backlink-agent.bridged.media/";
    break;
  case "production":
    apiURL = "https://gateway.bridged.media/";
    dashboardURL = "https://dashboard.bridged.media/";
    researchPartnerURL = "https://ai-agents-api.bridged.media/";
    discoveryAgentURL = "https://ai-agents-api.bridged.media/";
    summaryAgentURL = "https://summary-agent.bridged.media/";
    seoAgentURL = "https://seo-agent.bridged.media/";
    backlinkAgentURL = "https://backlink-agent.bridged.media/";
    break;
}

const getters = {
  getDashboardURL: (state) => { return state.dashboardURL; },
};

const state = () => ({
  apiUrl: apiURL,
  environment: environment,
  dashboardURL: dashboardURL,
  researchPartnerURL: researchPartnerURL,
  discoveryAgentURL: discoveryAgentURL,
  summaryAgentURL: summaryAgentURL,
  seoAgentURL: seoAgentURL,
  backlinkAgentURL: backlinkAgentURL,
  snackbarSettings: {
    color: "",
    text: "",
    isActive: false,
  },
});

const actions = {
  setSnackbar({ commit }, newSettings) {
    commit("setSnackbar", newSettings);
    setTimeout(() => {
      commit('disableSnackbar');
    }, 3000);
  },
};

const mutations = {
  setSnackbar(state, newSnackbarSettings) {
    state.snackbarSettings.isActive = true;
    state.snackbarSettings.text = newSnackbarSettings.text;
    state.snackbarSettings.color = newSnackbarSettings.color;
  },
  disableSnackbar(state) {
    state.snackbarSettings.isActive = false;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
