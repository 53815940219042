import helperApi from "../helper/helper.api";
import store from "../../store"

const researchPartnerReportAPI = "api/rp_agent/data_export";
const discoveryAgentReportAPI = "api/discovery_agent/data_export";
const summaryAgentReportAPI = "exportSummaryUsageData";
const seoAgentReportAPI = "exportSEOV3UsageData";
const backlinkAgentReportAPI = "exportBacklinkUsageData";


export default {
    async getResearchPartnerReports(requestParams) {
        const result = await
            helperApi.postRequest({
                url: store.state.appConfiguration.researchPartnerURL + researchPartnerReportAPI,
                payload: requestParams,
            });
        return result;
    },
    async getDiscoveryAgentReports(requestParams) {
        const result = await
            helperApi.postRequest({
                url: store.state.appConfiguration.discoveryAgentURL + discoveryAgentReportAPI,
                payload: requestParams,
            });
        return result;
    },
    async getSummaryAgentReports(requestParams) {
        const result = await
            helperApi.postRequest({
                url: store.state.appConfiguration.summaryAgentURL + summaryAgentReportAPI,
                payload: requestParams,
            });
        return result;
    },
    async getSEOAgentReports(requestParams) {
        const result = await
            helperApi.postRequest({
                url: store.state.appConfiguration.seoAgentURL + seoAgentReportAPI,
                payload: requestParams,
            });
        return result;
    },
    async getBacklinkAgentReports(requestParams) {
        const result = await
            helperApi.postRequest({
                url: store.state.appConfiguration.backlinkAgentURL + backlinkAgentReportAPI,
                payload: requestParams,
            });
        // replace service name value with backlink generator
        for (let i = 0; i < result.length; i++) {
            result[i].serviceName = "backlinkGenerator";
        }
        return result;
    },
    async getCampaignReportsForUser(requestParams) {
        console.log("requestParams", requestParams);
        const result = await
            helperApi.postRequest({
                url: store.state.appConfiguration.apiUrl + "Campaign/dataExport",
                payload: requestParams,
                needAuthroization: true,
                accessToken: requestParams.accessToken,
            });
        return result;
    }
}